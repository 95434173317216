var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "eventManagement-form-box" },
    [
      _c(
        "div",
        { staticClass: "form-left" },
        [
          _c("head-layout", {
            attrs: { "show-icon": false, "head-title": "处理进度" },
          }),
          _c(
            "el-steps",
            {
              staticStyle: {
                "margin-top": "30px",
                "align-items": "center",
                height: "50%",
              },
              attrs: { direction: "vertical", active: _vm.active },
            },
            [
              _c("el-step", { attrs: { title: "组织上报" } }),
              _c("el-step", { attrs: { title: "调查处理" } }),
              _c("el-step", { attrs: { title: "整改" } }),
              _c("el-step", { attrs: { title: "验收" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-right" },
        [
          _c("head-layout", {
            attrs: {
              "head-title": "事故管理",
              "head-btn-options": _vm.headBtnOptions,
            },
            on: {
              "head-save": function ($event) {
                return _vm.headSave(false)
              },
              "head-saveBack": function ($event) {
                return _vm.headSave(true)
              },
              "head-cancel": _vm.headCancel,
            },
          }),
          _c(
            "el-collapse",
            {
              attrs: { accordion: "" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "基本信息", name: "1" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      attrs: {
                        model: _vm.dataForm,
                        "label-width": "96px",
                        disabled:
                          _vm.formType == "view" || _vm.formType == "process",
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "事故编号", prop: "accCode" },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.dataForm.isAutomatic ||
                                          _vm.pageDisabled ||
                                          _vm.dataForm.id,
                                        placeholder: "请填写事故编号",
                                      },
                                      model: {
                                        value: _vm.dataForm.accCode,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.dataForm, "accCode", $$v)
                                        },
                                        expression: "dataForm.accCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "append" },
                                        [
                                          _vm._v(
                                            "\n                      自动生成\n                      "
                                          ),
                                          _c("el-switch", {
                                            attrs: {
                                              disabled:
                                                _vm.pageDisabled ||
                                                _vm.dataForm.id,
                                              "active-color": "#13ce66",
                                            },
                                            on: { change: _vm.handleGetCode },
                                            model: {
                                              value: _vm.dataForm.isAutomatic,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "isAutomatic",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.isAutomatic",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "发生时间", prop: "accTime" },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "datetime",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      placeholder: "请选择报告时间",
                                    },
                                    model: {
                                      value: _vm.dataForm.accTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "accTime", $$v)
                                      },
                                      expression: "dataForm.accTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "发生地点", prop: "address" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      title: _vm.dataForm.address,
                                      maxlength: "60",
                                      placeholder: "请填写发生地点",
                                    },
                                    model: {
                                      value: _vm.dataForm.address,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "address", $$v)
                                      },
                                      expression: "dataForm.address",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "事发单位",
                                    prop: "accOrgIdName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    on: {
                                      focus: function ($event) {
                                        return _vm.openDialog("deptDialog", {
                                          id: "accOrgId",
                                          name: "accOrgIdName",
                                        })
                                      },
                                    },
                                    model: {
                                      value: _vm.dataForm.accOrgIdName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "accOrgIdName",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.accOrgIdName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "事发班组",
                                    prop: "accClass",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.dataForm.accClass,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "accClass", $$v)
                                      },
                                      expression: "dataForm.accClass",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "报告人", prop: "userName" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请选择报告人" },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.openDialog("userDialog", {
                                          id: "userId",
                                          name: "userName",
                                        })
                                      },
                                    },
                                    model: {
                                      value: _vm.dataForm.userName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "userName", $$v)
                                      },
                                      expression: "dataForm.userName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "伤害程度",
                                    prop: "casualty",
                                  },
                                },
                                [
                                  _c("avue-select", {
                                    attrs: {
                                      props: {
                                        label: "dictValue",
                                        value: "dictKey",
                                      },
                                      placeholder: "请选择伤害程度",
                                      dic: _vm.casualtyArr,
                                    },
                                    model: {
                                      value: _vm.dataForm.casualty,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "casualty", $$v)
                                      },
                                      expression: "dataForm.casualty",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "上报人电话", prop: "tel" } },
                                [
                                  _c("el-input", {
                                    attrs: { maxlength: "11" },
                                    model: {
                                      value: _vm.dataForm.tel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "tel",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "dataForm.tel",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "事故描述",
                                    prop: "brifDesc",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2 },
                                      "show-word-limit": "",
                                      maxlength: "225",
                                      placeholder: "请输入事故描述",
                                    },
                                    model: {
                                      value: _vm.dataForm.brifDesc,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "brifDesc", $$v)
                                      },
                                      expression: "dataForm.brifDesc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "其他需要报告",
                                    prop: "otherReport",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2 },
                                      "show-word-limit": "",
                                      maxlength: "225",
                                      placeholder: "请输入其他需要报告",
                                    },
                                    model: {
                                      value: _vm.dataForm.otherReport,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "otherReport",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.otherReport",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "现场情况",
                                    prop: "treeData",
                                  },
                                },
                                [
                                  _vm.formType != "view"
                                    ? _c(
                                        "el-upload",
                                        {
                                          ref: "uploadRef1",
                                          staticClass: "upload-demo",
                                          staticStyle: {
                                            "margin-bottom": "12px",
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                          attrs: {
                                            accept: ".jpg,.mp4,.png",
                                            action:
                                              "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km",
                                            "show-file-list": false,
                                            "before-upload": _vm.checkFileType,
                                            "on-success":
                                              _vm.handleAvatarSuccess,
                                            headers: _vm.headers,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                                icon: "el-icon-upload",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "点击上传\n                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "content" },
                                    _vm._l(
                                      _vm.treeData,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.id,
                                            staticClass: "img-content",
                                            on: {
                                              click: function ($event) {
                                                return _vm.getNodeClick(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: item.thumbnailLink,
                                                alt: "",
                                              },
                                            }),
                                            _vm.formType !== "view"
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-close delete-icon",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.handleRemove(
                                                        item,
                                                        index
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dataForm.id && _vm.dataForm.accStatus !== "PREPARE"
                ? _c(
                    "el-collapse-item",
                    { attrs: { title: "事故调查", name: "2" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm1",
                          attrs: {
                            model: _vm.dataForm,
                            "label-width": "130px",
                            disabled: _vm.formType == "view",
                            rules: _vm.rules,
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "组织内调查人员",
                                        prop: "orgResearchUser",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.dataForm.orgResearchUser,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "orgResearchUser",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.orgResearchUser",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "组织外调查人员",
                                        prop: "orgOutResearchUser",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value:
                                            _vm.dataForm.orgOutResearchUser,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "orgOutResearchUser",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.orgOutResearchUser",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "事发部门",
                                        prop: "deptIdName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        on: {
                                          focus: function ($event) {
                                            return _vm.openDialog(
                                              "deptDialog",
                                              {
                                                id: "deptId",
                                                name: "deptIdName",
                                              }
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.dataForm.deptIdName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "deptIdName",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.deptIdName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "事故类别",
                                        prop: "accType",
                                      },
                                    },
                                    [
                                      _c("avue-select", {
                                        attrs: {
                                          props: {
                                            label: "dictValue",
                                            value: "dictKey",
                                          },
                                          placeholder: "请选择事故类别",
                                          type: "tree",
                                          dic: _vm.acc_record_type,
                                        },
                                        model: {
                                          value: _vm.dataForm.accType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "accType",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.accType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "事故级别",
                                        prop: "accLevel",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-container flex-align-c",
                                        },
                                        [
                                          _c("avue-select", {
                                            attrs: {
                                              props: {
                                                label: "dictValue",
                                                value: "dictKey",
                                              },
                                              placeholder: "请选择事故级别",
                                              type: "tree",
                                              dic: _vm.acc_record_level,
                                            },
                                            model: {
                                              value: _vm.dataForm.accLevel,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "accLevel",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.accLevel",
                                            },
                                          }),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                placement: "bottom",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "content",
                                                    fn: function () {
                                                      return [
                                                        _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.accLevelText
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                1680245037
                                              ),
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-question",
                                                staticStyle: {
                                                  "font-size": "24px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "事故性质",
                                        prop: "accSource",
                                      },
                                    },
                                    [
                                      _c("avue-select", {
                                        attrs: {
                                          props: {
                                            label: "dictValue",
                                            value: "dictKey",
                                          },
                                          placeholder: "请选择事故性质",
                                          dic: _vm.accSourceArr,
                                        },
                                        model: {
                                          value: _vm.dataForm.accSource,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "accSource",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.accSource",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "事故作业类别",
                                        prop: "accWorkType",
                                      },
                                    },
                                    [
                                      _c("avue-select", {
                                        attrs: {
                                          props: {
                                            label: "dictValue",
                                            value: "dictKey",
                                          },
                                          placeholder: "请选择事故作业类别",
                                          dic: _vm.accWorkTypeArr,
                                        },
                                        model: {
                                          value: _vm.dataForm.accWorkType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "accWorkType",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.accWorkType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "事故原因",
                                        prop: "reason",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-container flex-align-c",
                                        },
                                        [
                                          _c("avue-select", {
                                            attrs: {
                                              props: {
                                                label: "dictValue",
                                                value: "dictKey",
                                              },
                                              placeholder: "请选择事故原因",
                                              dic: _vm.reasonArr,
                                            },
                                            model: {
                                              value: _vm.dataForm.reason,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "reason",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.reason",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "事故责任人",
                                        prop: "accResponsUserIdName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        on: {
                                          focus: function ($event) {
                                            return _vm.openDialog(
                                              "userDialog",
                                              {
                                                id: "accResponsUserId",
                                                name: "accResponsUserIdName",
                                              }
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.dataForm.accResponsUserIdName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "accResponsUserIdName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.accResponsUserIdName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "死亡人数",
                                        prop: "lossDeath",
                                      },
                                    },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          min: 0,
                                          "step-strictly": "",
                                          step: 1,
                                          max: 9999,
                                          type: "number",
                                          placeholder: "请填写死亡人数",
                                        },
                                        model: {
                                          value: _vm.dataForm.lossDeath,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "lossDeath",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "dataForm.lossDeath",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "失踪人数",
                                        prop: "lossMiss",
                                      },
                                    },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          min: 0,
                                          "step-strictly": "",
                                          step: 1,
                                          max: 9999,
                                          type: "number",
                                          placeholder: "请填写失踪人数",
                                        },
                                        model: {
                                          value: _vm.dataForm.lossMiss,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "lossMiss",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "dataForm.lossMiss",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "中毒人数",
                                        prop: "lossPoison",
                                      },
                                    },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          min: 0,
                                          "step-strictly": "",
                                          step: 1,
                                          max: 9999,
                                          type: "number",
                                          placeholder: "请填写中毒人数",
                                        },
                                        model: {
                                          value: _vm.dataForm.lossPoison,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "lossPoison",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "dataForm.lossPoison",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "直接经济损失",
                                        prop: "lossFa",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            min: "0",
                                            type: "number",
                                            title: _vm.dataForm.lossFa,
                                            placeholder:
                                              "请输入直接经济损失评估结果",
                                          },
                                          model: {
                                            value: _vm.dataForm.lossFa,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "lossFa",
                                                $$v
                                              )
                                            },
                                            expression: "dataForm.lossFa",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("万元"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "间接经济损失",
                                        prop: "lossIndirect",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-container flex-align-c",
                                        },
                                        [
                                          _c("el-input-number", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              min: "0",
                                              controls: false,
                                              placeholder:
                                                "请输入间接经济损失评估结果",
                                            },
                                            model: {
                                              value: _vm.dataForm.lossIndirect,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "lossIndirect",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.lossIndirect",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "lossIndirect" },
                                            [_vm._v("万元")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "损失工作日",
                                        prop: "lossTime",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-container flex-align-c",
                                        },
                                        [
                                          _c("el-input-number", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              min: "0",
                                              controls: false,
                                              title: _vm.dataForm.lossTime,
                                              placeholder: "请输入损失工作日",
                                            },
                                            model: {
                                              value: _vm.dataForm.lossTime,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "lossTime",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.lossTime",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "lossIndirect" },
                                            [_vm._v("H")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "其他损失",
                                        prop: "lossOther",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入其他损失描述信息",
                                        },
                                        model: {
                                          value: _vm.dataForm.lossOther,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "lossOther",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.lossOther",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "社保工伤赔付",
                                        prop: "ssCompensate",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-container flex-align-c",
                                        },
                                        [
                                          _c("el-input-number", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              min: "0",
                                              controls: false,
                                              placeholder: "请输入社保工伤赔付",
                                            },
                                            model: {
                                              value: _vm.dataForm.ssCompensate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "ssCompensate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.ssCompensate",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "lossIndirect" },
                                            [_vm._v("万元")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "事故经过",
                                        prop: "accDesc",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { minRows: 2 },
                                          "show-word-limit": "",
                                          maxlength: "225",
                                          placeholder:
                                            "请输入事故发生的详细经过和原因",
                                        },
                                        model: {
                                          value: _vm.dataForm.accDesc,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "accDesc",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.accDesc",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "事故直接原因",
                                        prop: "directReason",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { minRows: 2 },
                                          "show-word-limit": "",
                                          maxlength: "225",
                                          placeholder: "请输入事故直接原因",
                                        },
                                        model: {
                                          value: _vm.dataForm.directReason,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "directReason",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.directReason",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "事故间接原因",
                                        prop: "indirectReason",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { minRows: 2 },
                                          "show-word-limit": "",
                                          maxlength: "225",
                                          placeholder: "请输入事故间接原因",
                                        },
                                        model: {
                                          value: _vm.dataForm.indirectReason,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "indirectReason",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.indirectReason",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "根本原因",
                                        prop: "rootReason",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { minRows: 2 },
                                          "show-word-limit": "",
                                          maxlength: "225",
                                          placeholder:
                                            "请输入事故发生的根本原因",
                                        },
                                        model: {
                                          value: _vm.dataForm.rootReason,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "rootReason",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.rootReason",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "经验教训",
                                        prop: "lesson",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { minRows: 2 },
                                          "show-word-limit": "",
                                          maxlength: "225",
                                          placeholder: "请输入经验教训",
                                        },
                                        model: {
                                          value: _vm.dataForm.lesson,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "lesson",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.lesson",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "备注（处理意见）",
                                        prop: "remark",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { minRows: 2 },
                                          "show-word-limit": "",
                                          maxlength: "225",
                                          placeholder: "请输入备注（处理意见）",
                                        },
                                        model: {
                                          value: _vm.dataForm.remark,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "remark",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.remark",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "要求整改措施",
                                        prop: "rectifyRequest",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { minRows: 2 },
                                          "show-word-limit": "",
                                          maxlength: "225",
                                          placeholder: "请输入要求整改措施",
                                        },
                                        model: {
                                          value: _vm.dataForm.rectifyRequest,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "rectifyRequest",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.rectifyRequest",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "整改责任部门",
                                        prop: "rectifyDeptName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.openDialog(
                                              "deptDialog",
                                              {
                                                id: "rectifyDeptId",
                                                name: "rectifyDeptName",
                                              }
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.dataForm.rectifyDeptName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "rectifyDeptName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.rectifyDeptName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "整改责任人",
                                        prop: "rectifyUserName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        on: {
                                          focus: function ($event) {
                                            return _vm.openDialog(
                                              "userDialog",
                                              {
                                                id: "rectifyUser",
                                                name: "rectifyUserName",
                                              }
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.dataForm.rectifyUserName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "rectifyUserName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.rectifyUserName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "整改期限",
                                        prop: "rectifyLimitDate",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "datetime",
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                          placeholder: "请选择整改期限",
                                        },
                                        model: {
                                          value: _vm.dataForm.rectifyLimitDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "rectifyLimitDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.rectifyLimitDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "验收部门",
                                        prop: "acceptDeptName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.openDialog(
                                              "deptDialog",
                                              {
                                                id: "acceptDept",
                                                name: "acceptDeptName",
                                              }
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.dataForm.acceptDeptName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "acceptDeptName",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.acceptDeptName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "验收责任人",
                                        prop: "acceptUserName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        on: {
                                          focus: function ($event) {
                                            return _vm.openDialog(
                                              "userDialog",
                                              {
                                                id: "acceptUser",
                                                name: "acceptUserName",
                                              }
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.dataForm.acceptUserName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "acceptUserName",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.acceptUserName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "调查报告",
                                        prop: "treeData1",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          staticClass: "upload-demo",
                                          attrs: {
                                            "on-preview": _vm.handlePreview,
                                            disabled: _vm.formType == "view",
                                            accept: ".rar,.zip,.doc,.docx,.pdf",
                                            action:
                                              "api/sinoma-resource/oss/endpoint/put-file",
                                            "on-success":
                                              _vm.handleAvatarSuccess1,
                                            "before-upload": _vm.checkFileType1,
                                            "on-remove": _vm.handleRemove1,
                                            headers: _vm.headers,
                                            multiple: "",
                                            "file-list": _vm.treeData1,
                                          },
                                        },
                                        [
                                          _vm.formType != "view"
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "small",
                                                    type: "primary",
                                                    icon: "el-icon-upload",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "点击上传\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.formType != "view"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "el-upload__tip",
                                                  attrs: { slot: "tip" },
                                                  slot: "tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      支持扩展名：.rar .zip .doc .docx .pdf\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dataForm.id && _vm.dataForm.accStatus !== "PREPARE"
                ? _c(
                    "el-collapse-item",
                    { attrs: { title: "安全处罚措施", name: "3" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm2",
                          attrs: {
                            model: _vm.dataForm,
                            "label-width": "130px",
                            disabled: _vm.formType == "view",
                            rules: _vm.rules,
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "考核对象",
                                        prop: "assObject",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          on: { change: _vm.groupChange },
                                          model: {
                                            value: _vm.dataForm.assObject,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm,
                                                "assObject",
                                                $$v
                                              )
                                            },
                                            expression: "dataForm.assObject",
                                          },
                                        },
                                        [
                                          _c("el-radio", {
                                            attrs: { label: "人员" },
                                          }),
                                          _c("el-radio", {
                                            attrs: { label: "部门" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.dataForm.assObject == "人员"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "人员",
                                            prop: "assUserName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            on: {
                                              focus: function ($event) {
                                                return _vm.openDialog(
                                                  "userDialog",
                                                  {
                                                    id: "assUserId",
                                                    name: "assUserName",
                                                  }
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.dataForm.assUserName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "assUserName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.assUserName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.dataForm.assObject == "部门"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "部门",
                                            prop: "assDeptName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            on: {
                                              focus: function ($event) {
                                                return _vm.openDialog(
                                                  "deptDialog",
                                                  {
                                                    id: "assDeptId",
                                                    name: "assDeptName",
                                                  }
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.dataForm.assDeptName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "assDeptName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.assDeptName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "扣分", prop: "points" },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.dataForm.points,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "points",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "dataForm.points",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "经济处罚（元）",
                                        prop: "fine",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.dataForm.fine,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "fine",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "dataForm.fine",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "教育培训（学时）",
                                        prop: "eduTrain",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.dataForm.eduTrain,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "eduTrain",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "dataForm.eduTrain",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "待岗（月）",
                                        prop: "awaitJob",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.dataForm.awaitJob,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "awaitJob",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "dataForm.awaitJob",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formType == "view"
                ? _c(
                    "el-collapse-item",
                    { attrs: { title: "整改信息", name: "4" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm3",
                          attrs: {
                            model: _vm.dataForm,
                            "label-width": "96px",
                            disabled: _vm.formType == "view",
                            rules: _vm.rules,
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "整改部门" } },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.dataForm.rectifyDeptName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "rectifyDeptName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.rectifyDeptName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "整改人" } },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value:
                                            _vm.dataForm.hdRectifyVO
                                              .actualRectifyUserName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm.hdRectifyVO,
                                              "actualRectifyUserName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.hdRectifyVO.actualRectifyUserName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "整改时间" } },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "date",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "请选择整改期限",
                                        },
                                        model: {
                                          value:
                                            _vm.dataForm.hdRectifyVO
                                              .rectifyDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm.hdRectifyVO,
                                              "rectifyDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.hdRectifyVO.rectifyDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "整改结果" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { minRows: 2 },
                                          "show-word-limit": "",
                                          maxlength: "225",
                                          placeholder: "请输入整改结果",
                                        },
                                        model: {
                                          value:
                                            _vm.dataForm.hdRectifyVO
                                              .rectifyMethod,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm.hdRectifyVO,
                                              "rectifyMethod",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.hdRectifyVO.rectifyMethod",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "整改后图片" } },
                                    [
                                      _vm._l(
                                        _vm.dataForm.hdRectifyVO.rectifyUrl
                                          ? JSON.parse(
                                              _vm.dataForm.hdRectifyVO
                                                .rectifyUrl
                                            )
                                          : [].map((item) => {
                                              return item.link
                                            }),
                                        function (item, index) {
                                          return [
                                            _c("el-image", {
                                              key: index,
                                              staticStyle: {
                                                width: "100px",
                                                height: "100px",
                                              },
                                              attrs: {
                                                src: item,
                                                "preview-src-list": [item],
                                              },
                                            }),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formType == "view"
                ? _c(
                    "el-collapse-item",
                    { attrs: { title: "验收信息", name: "5" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm4",
                          attrs: {
                            model: _vm.dataForm,
                            "label-width": "96px",
                            disabled: _vm.formType == "view",
                            rules: _vm.rules,
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "验收结果" } },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value:
                                              _vm.dataForm.hdAcceptVO
                                                .acceptFlag,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataForm.hdAcceptVO,
                                                "acceptFlag",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dataForm.hdAcceptVO.acceptFlag",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "y" } },
                                            [_vm._v("验收通过")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "n" } },
                                            [_vm._v("验收不通过")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "验收时间" } },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "date",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "请选择验收时间",
                                        },
                                        model: {
                                          value:
                                            _vm.dataForm.hdAcceptVO.acceptDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm.hdAcceptVO,
                                              "acceptDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.hdAcceptVO.acceptDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "验收人" } },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value:
                                            _vm.dataForm.hdAcceptVO
                                              .actualAcceptUserName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm.hdAcceptVO,
                                              "actualAcceptUserName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.hdAcceptVO.actualAcceptUserName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "验收意见" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          autosize: { minRows: 2 },
                                          "show-word-limit": "",
                                          maxlength: "225",
                                          placeholder: "请输入验收意见",
                                        },
                                        model: {
                                          value:
                                            _vm.dataForm.hdAcceptVO
                                              .acceptOpinion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm.hdAcceptVO,
                                              "acceptOpinion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataForm.hdAcceptVO.acceptOpinion",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "验收图片" } },
                                    [
                                      _vm._l(
                                        _vm.dataForm.hdAcceptVO.acceptUrl
                                          ? JSON.parse(
                                              _vm.dataForm.hdAcceptVO.acceptUrl
                                            )
                                          : [].map((item) => {
                                              return item.link
                                            }),
                                        function (item, index) {
                                          return [
                                            _c("el-image", {
                                              key: index,
                                              staticStyle: {
                                                width: "100px",
                                                height: "100px",
                                              },
                                              attrs: {
                                                src: item,
                                                "preview-src-list": [item],
                                              },
                                            }),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userDialog,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userDialog = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "部门选择", visible: _vm.deptDialog, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptDialog = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            attrs: {
              deptCategory: [2, 5],
              parentDeptId: _vm.userInfo.company_id,
            },
            on: { "select-data": _vm.deptBack },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }